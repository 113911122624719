import React from "react";
import classes from "./UserInformationDetail.module.scss";
import UserInformation from "./UserInformation";
import EmployeeSkills from "../standard/EmployeeSkills";
import { skillListTest } from "../../utils/utils";

const UserInformationDetail = ({ employee }) => {

  return (
    <div className={classes.userInfoContainer}>
      <UserInformation user={employee} showSkillsUnderShortName={false} />
      <EmployeeSkills licenses={employee?.licences ?? []} skillList={employee.skills}  badgeShape="square"/>
    </div>
  );
};

export { UserInformationDetail };
