import React from "react";
import classes from './UserAvatar.module.scss';
import { getContrastColor, skillListTest } from "../../utils/utils";
import { useEffect } from "react";
import EmployeeSkills from "../standard/EmployeeSkills";

const UserAvatar = ({ employee, size = 'normal', addMargin, hideSkills = true }) => {
  console.log('*employee',employee)
  return (employee &&
    <div className={`${classes.employeeAvatar} ${size === 'small' ? classes.smallAvatar : ''} ${addMargin ? classes.addMargin : ''}`}>
      <div
        className={employeeSquare[size]}
        style={{
          backgroundColor: employee.roleColor ?? '#add8e6',
          color: getContrastColor(employee.roleColor ?? '#add8e6')
        }}>
        <span className={classes.shortName}>{getShortName(employee)}</span>
      </div>
      {size !== 'small' && !hideSkills && <EmployeeSkills licenses={[...(employee.licences ?? [])]} skillList={employee.skills} showLabel={false} badgeShape="circle" />}
    </div>
  );
}

const employeeSquare = {
  normal: classes.normalSquare,
  small: classes.smallSquare
}

function getShortName(employee) {
  return employee?.userName?.split(' ').map(l=>l[0]).slice(0,2).join('') || `${employee.name?.slice(0, 1) ?? ''}${employee.lastName?.slice(0, 1) ?? ''}`.toUpperCase();
}


export default UserAvatar;