import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOpenCategoriesInitialState,
  getOpenCategoriesCrewOptions,
} from "../components/services/filterService";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    categories: [
      { id: 1, name: "Aircraft Type" },
      { id: 2, name: "Endorsements" },
      { id: 3, name: "Crew" },
      { id: 4, name: "Licenses" },
    ],
    openCategories: [],
    selectedFilters: [],
    status: "idle",
    error: null,
  },
  reducers: {
    toggleFilterCategory: (state, action) => {

      const categoryId = action.payload;
      const index = state.openCategories.indexOf(categoryId);

      if (index === -1) {
        state.openCategories.push(categoryId);
      } else {
        state.openCategories.splice(index, 1);
      }
    },
    setOpenCategories: (state, action) => {
      state.openCategories = action.payload;
    },
    setSelectedFilters: (state, action) => {
      const openCategories = action.payload;
      const filters = [
        {
          id: 1,
          name: "airCraft",
          filterIds: [],
        },
        {
          id: 2,
          name: "endorsements",
          filterIds: [],
        },
        {
          id: 3,
          name: "crew",
          filterIds: [],
        },
        {
          id: 4,
          name: "licenes",
          filterIds: [],
        },
      ];
      for (let index = 0; index < 4; index++) {
        const category = openCategories[index];
        if (category.categories.length > 0) {
          category.categories.forEach((cat) => {
            if (cat.selected === true) {
              filters[index].filterIds.push(cat.id);
            }
          });
        }
      }
      state.selectedFilters = filters;
    },

    clearFilter(state, action) {
      let updatedOpenCategories = state.openCategories.filter(c => c.categories).map(section=>{
        section.categories = section.categories.map(category=>{
          category.selected=false;
          return category;
        });
        if (section.id===2){
          section.categories = [];
        }
        return section;
      });
      state.openCategories = updatedOpenCategories;

    },
    updateFilterCategory(state, action) {
      const { foundCategory, parentCategory, filter } = action.payload;

      const foundParentCategory = state.openCategories.find(
        (category) => category.id === parentCategory.id
      );

      const foundCategoryIndex = foundParentCategory.categories.findIndex(
        (category) => category.id === foundCategory.id
      );

      // Create a new updated category object
      const updatedCategory = {
        ...foundCategory,
        selected: !foundCategory.selected,
      };

      // Create a new updated categories array with the updated category
      const updatedCategories = [
        ...foundParentCategory.categories.slice(0, foundCategoryIndex),
        updatedCategory,
        ...foundParentCategory.categories.slice(foundCategoryIndex + 1),
      ];

      // Create a new updated parent category object with the updated categories
      const updatedParentCategory = {
        ...foundParentCategory,
        categories: updatedCategories,
      };

      // Create a new updated openCategories array with the updated parent category
      const updatedOpenCategories = [
        ...state.openCategories.slice(
          0,
          state.openCategories.indexOf(foundParentCategory)
        ),
        updatedParentCategory,
        ...state.openCategories.slice(
          state.openCategories.indexOf(foundParentCategory) + 1
        ),
      ];

      if (filter.length > 0 && updatedCategory.selected === true) {
        const updatedFilters = [];

        if (updatedOpenCategories[1].categories?.length > 0) {
          updatedOpenCategories[1].categories.forEach((ele) => {
            if (!updatedFilters.includes(ele)) {
              return updatedFilters.push(ele);
            }
          });
          filter.forEach((ele) => {
            if (!updatedFilters.some((cat) => cat.id === ele.id)) {
              updatedFilters.push(ele);
            }
          });
        } else {
          filter.forEach((ele) => updatedFilters.push(ele));
        }

        updatedOpenCategories[1] = {
          ...updatedOpenCategories[1],
          categories: updatedFilters,
        };
      }

      if (updatedCategory.selected === false && parentCategory.id === 1) {
        let updatedFilters = [];

        updatedOpenCategories[0].categories.forEach((category) => {
          if (category.selected === true && category.endorsements.length > 0) {
            category.endorsements.forEach((ele) => {
              if (!updatedFilters.some((cat) => cat.id === ele.id)) {
                updatedFilters.push(ele);
              }
            });
          }
        });

        //verify selected value
        updatedFilters = updatedFilters.map((el) => {
          const matchingEndorsement = state.openCategories[1].categories.find(
            (endorsement) => endorsement.id === el.id
          );
          if (matchingEndorsement) {
            return {
              ...el,
              selected: matchingEndorsement.selected,
            };
          } else {
            return el;
          }
        });

        updatedOpenCategories[1] = {
          ...updatedOpenCategories[1],
          categories: updatedFilters,
        };
      }

      state.openCategories = updatedOpenCategories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategoriesOptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.openCategories = action.payload;
      })
      .addCase(fetchCategoriesOptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const fetchCategoriesOptions = createAsyncThunk(
  "filter/fetchCategoriesOptions",
  async (locationId) => {
    const response = await getOpenCategoriesInitialState(locationId);
    if (response) {
      return response;
    }
  }
);

export const fetchCrewOptions = createAsyncThunk(
  "filter/fetchCrewOptions",
  async (locationId, { getState, dispatch }) => {
    const openCategories = openCategoriesState(getState());
    if (openCategories?.length === 0) {
      await dispatch(fetchCategoriesOptions(locationId));
    } else {
      const response = await getOpenCategoriesCrewOptions(locationId);
      if (response.load) {
        const updatedArray = openCategories.map((item) => {
          if (item.id === 3) {
            return {
              ...item,
              categories: response.load.map((responseItem) => {
                return {
                  id: responseItem.id,
                  name: responseItem.groupName,
                  locationId: responseItem.locationId,
                };
              }),
            };
          } else {
            return item;
          }
        });
        dispatch(setOpenCategories(updatedArray));
      }
    }
  }
);

export const categoriesState = (state) => state.filter.categories;
export const openCategoriesState = (state) => state.filter.openCategories;
export const selectedFiltersState = (state) => state.filter.selectedFilters;

export const {
  toggleFilterCategory,
  updateFilterCategory,
  setSelectedFilters,
  setOpenCategories,
  clearFilter
} = filterSlice.actions;

export default filterSlice.reducer;
